import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";

window.initScripts = () => {
    let loadScripts = false;
    const check = localStorage.getItem("consent");
    if (check === "true") {
        loadScripts = true;
    } else if (check === "false") {
        loadScripts = false;
    } else {
        const consent = document.getElementById("cookie-consent");
        if (!consent) {
            return;
        }
        consent.className = "cookie-consent visible";
    }

    if (loadScripts) {
        const scriptsHead = [
            // Google Analytics / Tag-Manager
            { src: "https://www.googletagmanager.com/gtag/js?id=G-3M7GVF31SG", async: true },
            "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-3M7GVF31SG');",
        ];

        if (scriptsHead && scriptsHead.length > 0) {
            for (let script of scriptsHead) {
                if (typeof script === "string") {
                    if (!script || script.trim() === "") continue;

                    const element = document.createElement("script");
                    element.text = script;
                    document.head.appendChild(element);
                } else if (typeof script === "object" && script.src && script.src.trim() !== "") {
                    const element = document.createElement("script");
                    element.src = script.src;
                    element.async = script.async === true ? true : undefined;
                    document.head.appendChild(element);
                }
            }
        }
    }
};
